<template>
  <div class="visual-body">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item class="search-form-gutter" :label="$t('visual.when')">
          <el-select
            style="width: 118px"
            v-model="when"
            @change="changeDate"
            clearable
            filterable
          >
            <el-option
              v-for="item in whenList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('visual.place')">
          <el-select
            style="width: 138px"
            v-model="place"
            @change="changePlace"
            clearable
            filterable
          >
            <el-option
              v-for="item in placeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('visual.sex')">
          <el-select
            style="width: 104px"
            v-model="sex"
            @change="changeSex"
            clearable
            filterable
          >
            <el-option
              v-for="item in sexList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="group === 'street' ? $t('common.item') : $t('common.brand')"
        >
          <el-select style="width: 148px" v-model="item" clearable filterable>
            <el-option
              v-for="item in itemList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-btn">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="clickSearch"
          >{{ $t("common.search") }}</el-button
        >
      </div>
      <div class="download-btn">
        <el-button
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="downloadAll"
          >{{ $t("analysis.downloadAll") }}</el-button
        >
      </div>
    </div>
    <div class="data-content">
      <p v-if="loading" class="data-loading">
        <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
      </p>
      <p v-else-if="!tableData || tableData.length === 0" class="data-loading">
        {{ $t("common.noMoreData") }}
      </p>
      <el-row v-else :gutter="10">
        <el-col
          class="data-block"
          :lg="{ span: '5-24' }"
          v-for="item in tableData"
          :key="item.id"
        >
          <div class="data-block-content">
            <el-image
              class="content-image"
              :src="item.url"
              :preview-src-list="[item.url]"
              fit="contain"
            />
            <div>
              <el-row class="content-line">
                <span class="content-label">{{ $t("visual.when") }}</span>
                {{ item.date }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{ $t("visual.place") }}</span>
                {{ item.place }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{ $t("visual.sex") }}</span>
                {{ item.sex }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  group === "street" ? $t("common.item") : $t("common.brand")
                }}</span>
                {{ group === "street" ? item.item : item.brand }}
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="() => fetchData()"
    />
    <el-dialog
      :title="$t('common.downloading')"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      :before-close="downloadClick"
    >
      <p>
        {{
          isDownloading
            ? $t("analysis.downloadImages", {
                finishCount,
                totalCount: total,
              })
            : $t("analysis.finishDownload")
        }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadClick">{{
          isDownloading ? $t("common.abort") : $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('common.prompt')"
      :visible.sync="serviceDialogVisible"
    >
      {{ $t("order.noValidService") }}
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="serviceDialogVisible = false">{{
          $t("common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="goServiceList">{{
          $t("common.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import {
  fetchVisual,
  fetchDate,
  fetchPlace,
  fetchItem,
  fetchSex,
} from "@/api/visual";
import { downloadFile } from "@/utils/download-file";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      whenList: [],
      placeList: [],
      sexList: [],
      itemList: [],
      when: null,
      place: null,
      sex: null,
      item: null,
      brand: null,
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      loading: false,
      finishCount: 0,
      dialogVisible: false,
      isDownloading: false,
      serviceDialogVisible: false,
    };
  },
  created() {
    this.initListQuery();
    this.fetchData();
    this.fetchDate();
    this.fetchPlace();
    this.fetchSex();
    this.fetchItem();
  },
  methods: {
    initListQuery() {
      this.listQuery = {
        offset: 0,
        limit: 10,
      };
    },
    fetchData(cb) {
      let query = { ...this.listQuery };
      if (cb) {
        query = { limit: -1 };
      }
      query.type = this.group;
      query.date = this.when;
      query.place = this.place;
      query.sex = this.sex;
      query.item = this.item;
      fetchVisual(query).then((response) => {
        if (response.success) {
          if (cb) {
            cb(response.result.items);
          } else {
            this.tableData = response.result.items;
            this.total = response.result.totalCount;
          }
        }
      });
    },
    clickSearch() {
      this.initListQuery();
      this.fetchData();
    },
    fetchDate() {
      fetchDate({ type: this.group }).then((response) => {
        if (response.success) {
          this.whenList = response.result.items;
        }
      });
    },
    changeDate() {
      this.place = null;
      this.sex = null;
      this.item = null;
      this.fetchPlace();
      this.fetchSex();
      this.fetchItem();
    },
    fetchPlace() {
      fetchPlace({ type: this.group, when: this.when }).then((response) => {
        if (response.success) {
          this.placeList = response.result.items;
        }
      });
    },
    changePlace() {
      this.sex = null;
      this.item = null;
      this.fetchSex();
      this.fetchItem();
    },
    fetchSex() {
      fetchSex({ type: this.group, when: this.when, place: this.place }).then(
        (response) => {
          if (response.success) {
            this.sexList = response.result.items;
          }
        }
      );
    },
    changeSex() {
      this.item = null;
      this.fetchItem();
    },
    fetchItem() {
      fetchItem({
        type: this.group,
        when: this.when,
        place: this.place,
        sex: this.sex,
      }).then((response) => {
        if (response.success) {
          this.itemList = response.result.items;
        }
      });
    },
    download(path) {
      downloadFile(path, path ? path.substring(path.lastIndexOf("/") + 1) : "");
    },
    downloadAll() {
      let allow = false;
      let serviceCodes =
        this.$store.getters.my && this.$store.getters.my.serviceCodes
          ? this.$store.getters.my.serviceCodes
          : [];
      for (let i = 0; i < serviceCodes.length; i++) {
        if (serviceCodes[i] === "visual") {
          allow = true;
        }
      }
      if (!allow) {
        this.serviceDialogVisible = true;
        return;
      }
      this.fetchData((res) => {
        this.isDownloading = true;
        this.dialogVisible = true;
        const doDownload = (i) => {
          if (this.isDownloading && res.length > i) {
            this.finishCount = i;
            const url = res[i].url;
            downloadFile(
              url,
              url ? url.substring(url.lastIndexOf("/") + 1) : "",
              () => {
                doDownload(i + 1);
              }
            );
          } else {
            this.finishCount = 0;
            this.isDownloading = false;
          }
        };
        doDownload(0);
      });
    },
    downloadClick() {
      this.isDownloading = false;
      this.dialogVisible = false;
      this.finishCount = 0;
    },
    goServiceList() {
      this.$router.push("/my/order").catch(() => {});
    },
  },
  computed: {
    group() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["type"];
    },
  },
};
</script>

<style lang="scss" scoped>
.visual-body {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .search-btn {
      float: left;
    }

    .download-btn {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .data-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;

    .data-loading {
      text-align: center;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;

        .content-image {
          width: 100%;
          height: 0;
          padding-bottom: 100%;

          /deep/ img {
            position: absolute;
            margin-bottom: 5px;
          }
        }

        .content-line {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);
          padding-left: 10px;

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
</style>