import request from '@/utils/request'

export function fetchVisual(query) {
  return request({
    url: '/v1/visual-files',
    method: 'get',
    params: query
  })
}

export function fetchDate(query) {
  return request({
    url: '/v1/visual-files/date',
    method: 'get',
    params: query
  })
}

export function fetchPlace(query) {
  return request({
    url: '/v1/visual-files/place',
    method: 'get',
    params: query
  })
}

export function fetchSeason(query) {
  return request({
    url: '/v1/visual-files/season',
    method: 'get',
    params: query
  })
}

export function fetchItem(query) {
  return request({
    url: '/v1/visual-files/item',
    method: 'get',
    params: query
  })
}

export function fetchSex(query) {
  return request({
    url: '/v1/visual-files/sex',
    method: 'get',
    params: query
  })
}